.not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
    /* Light gray background color */
}

.not-found-content {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    /* White background color */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Subtle box shadow for depth */
}

.not-found-title {
    font-size: 48px;
    color: #e74c3c;
    /* Red color for the title */
    margin-bottom: 20px;
}

.not-found-text {
    font-size: 18px;
    color: #555555;
    /* Dark gray color for the text */
}