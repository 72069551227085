/* App.css */
body {
  margin: 0;
  padding: 0;
}

.nav-links li a svg {
  font-size: 25px !important;
}

.nav-links li a span {
  font-weight: 700 !important;
}

.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(54, 54, 54, 0.7);
  z-index: 9999;
}

.bg-lp {
  background-color: #6d4e8a;
}

.text-lp {
  color: #6d4e8a;
}

.app-container {
  display: flex;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #6d4e8a;
  color: #c6a4c8;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 10px;
}

.logo span {
  font-size: 1.3rem;
  color: white;
}

.content {
  margin-left: 250px;
  height: 100vh;
  flex: 1;
  padding: 20px;
  background-color: #f5f5f5;
  /* Set a background color for the content area */
}

.nav-links {
  list-style: none;
  padding: 0;
}

.nav-links li {
  margin-bottom: 15px;
}

.nav-links a {
  color: #c6a4c8;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.nav-links svg {
  margin-right: 5px;
}

.nav-links a:hover {
  color: #ffffff;
}

.logout-btn {
  margin-top: auto;
  /* Push the logout button to the bottom of the sidebar */
}

.logout-btn a {
  color: #c6a4c8;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logout-btn a:hover {
  color: #ffffff;
}

.active-link {
  background-color: #9a7aaa;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.input-container {
  position: relative;
}

.input-container input {
  padding-right: 30px;
  /* Adjust as needed */
}

.input-container .calendar-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  /* Adjust as needed */
  transform: translateY(-50%);
}

.dashboard-widget-card .card {
  background: white;
  background-image: url('./assets/card-bg.png');
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.dashboard-widget-card .card .card-body h5 {
  padding-bottom: 1rem;
}

table tbody tr td {
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  /* Hide sidebar by default on small screens */
  .sidebar {
    display: none;
  }

  /* Show sidebar when it has the 'open' class */
  .sidebar.open {
    display: block;
    width: 250px;
    height: 100vh;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    z-index: 1000;
    padding-top: 60px;
  }

  .content {
    margin-left: 0;
    padding-top: 60px;
  }

  .toggle-button {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: #333;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    z-index: 1001;
  }
}

@media only screen and (min-width: 769px) {
  .toggle-button {
    display: none;
  }

  .content {
    margin-left: 250px;
    padding: 20px;
  }
}